import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

interface IResultProps {

    data:{ cid: any; priority: any; type: any; detail: any; position: any; recheck: any; }[]
    // {
    //     cid: item.cid,
    //     priority: item.priority,
    //     type: item.type,
    //     detail: item.detail,
    //     position: item.position,
    //     recheck: item.recheck
    // }
    
}

export default function ResultTable(props:IResultProps) {

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>編號</TableCell>
            <TableCell align="right">分類</TableCell>
            <TableCell align="right">位置</TableCell>
            <TableCell align="left">項目</TableCell>
            <TableCell align="right">標記</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.filter( row => row.recheck === true).map((row) => (
            <TableRow
              key={row.cid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.cid}
              </TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{row.position}</TableCell>
              <TableCell align="left">{row.detail}</TableCell>
              <TableCell align="right">{row.recheck ? "❌" : "✅" }</TableCell>
            </TableRow>
          ))}


          {props.data.filter( row => row.recheck === false || row.recheck == undefined).map((row) => (
            <TableRow
              key={row.cid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.cid}
              </TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{row.position}</TableCell>
              <TableCell align="left">{row.detail}</TableCell>
              <TableCell align="right">{row.recheck ? "❌" : "✅" }</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}