import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

interface CheckInfo {

    cid: number
    priority: number | string
    type: string
    detail: string
    positions: string[]
    markedAsRechecked: boolean
    
    handleNext: () => void
    handlePrivious: () => void
    handleMarkAsRecheck: () => void
}

export default function CheckInfoCard(props: CheckInfo) {
    return (
        <Card sx={{ maxWidth: "100%" }}>
            <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
            />
            {/* <svg viewBox="0 0 342 35" xmlns="http://www.w3.org/2000/svg"><path d="M0 .1a9.7 9.7 0 007 7h11l.5.1v27.6h6.8V7.3L26 7h11a9.8 9.8 0 007-7H0zm238.6 0h-6.8v34.8H263a9.7 9.7 0 006-6.8h-30.3V0zm-52.3 6.8c3.6-1 6.6-3.8 7.4-6.9l-38.1.1v20.6h31.1v7.2h-24.4a13.6 13.6 0 00-8.7 7h39.9v-21h-31.2v-7h24zm116.2 28h6.7v-14h24.6v14h6.7v-21h-38zM85.3 7h26a9.6 9.6 0 007.1-7H78.3a9.6 9.6 0 007 7zm0 13.8h26a9.6 9.6 0 007.1-7H78.3a9.6 9.6 0 007 7zm0 14.1h26a9.6 9.6 0 007.1-7H78.3a9.6 9.6 0 007 7zM308.5 7h26a9.6 9.6 0 007-7h-40a9.6 9.6 0 007 7z"></path></svg> */}
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">

                        <span>{props.type}</span>
                    <Stack direction="row" spacing={1} component="span">  
                        {
                            props.positions.map((position: string) => (
                                position !== "" &&
                                    <Chip label={position} color="primary" component="span" />
                            ))
                        }
                    </Stack>
                </Typography>

                <Typography variant="body2" color="text.secondary" component="div">
                    {props.detail}
                </Typography>

            </CardContent>
            <CardContent>
            { props.markedAsRechecked &&
                <Chip label="已標記問題" color="warning" component="span" />
            }
            </CardContent>
            <CardActions>
                <Button size="small" onClick={props.handleNext}>完成</Button>
                <Button size="small" onClick={props.handlePrivious}>回上一項</Button>
                <Button size="small" onClick={props.handleMarkAsRecheck}>{ props.markedAsRechecked ? "取消標記": "標記問題"}</Button>
                
            </CardActions>
            
        </Card>
    );
}
