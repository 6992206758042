
export const data = [
    {
      "cid": 1,
      "priority": "",
      "type": "APP",
      "detail": "APP是否可遠端操控(開關窗、哨兵模式、空調、前後行李箱、喇叭、閃燈)",
      "position": "正駕"
    },
    {
      "cid": 2,
      "priority": 10,
      "type": "中央扶手",
      "detail": "Keycard感應是否正常",
      "position": "正駕"
    },
    {
      "cid": 3,
      "priority": 10,
      "type": "中央扶手",
      "detail": "Type C充電孔(4組)",
      "position": "正駕"
    },
    {
      "cid": 4,
      "priority": "",
      "type": "中央扶手",
      "detail": "12V點煙孔",
      "position": "正駕"
    },
    {
      "cid": 5,
      "priority": 10,
      "type": "中央扶手",
      "detail": "無線充電是否正常(2組)",
      "position": "正駕"
    },
    {
      "cid": 6,
      "priority": 9,
      "type": "中央扶手",
      "detail": "中央扶手位置是否置中",
      "position": "正駕"
    },
    {
      "cid": 7,
      "priority": 8,
      "type": "中央扶手",
      "detail": "中控蓋是否可正常開啟(燈是否亮)",
      "position": "正駕"
    },
    {
      "cid": 8,
      "priority": 8,
      "type": "中央扶手",
      "detail": "置杯架是否卡卡",
      "position": "正駕"
    },
    {
      "cid": 9,
      "priority": 9,
      "type": "內飾",
      "detail": "遮陽板是否正常(梳妝鏡燈)、室內頭燈正常",
      "position": "正駕,副駕"
    },
    {
      "cid": 10,
      "priority": 9,
      "type": "內飾",
      "detail": "ABC柱是否服貼",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 11,
      "priority": 8,
      "type": "內飾",
      "detail": "車內絨布是否有汙點",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 12,
      "priority": 8,
      "type": "內飾",
      "detail": "掛衣鉤是否正常",
      "position": "乘客左,乘客右"
    },
    {
      "cid": 13,
      "priority": 10,
      "type": "文件",
      "detail": "發票(買受人/金額)",
      "position": ""
    },
    {
      "cid": 14,
      "priority": 10,
      "type": "文件",
      "detail": "新領牌登記書\nVIN 碼、車主名、ID、生日、地址、車輛型式、出廠年月、車式、車色",
      "position": ""
    },
    {
      "cid": 15,
      "priority": 10,
      "type": "文件",
      "detail": "車身VIN碼(駕駛座前檔玻璃下方)、行照與新領牌登記書，三者一致",
      "position": ""
    },
    {
      "cid": 16,
      "priority": 10,
      "type": "文件",
      "detail": "取回(1)身分證、(2)行照、(3)印章、(4)新領牌登記書、(5)代辦金額明細、(6)領牌收據(1250 )、(7)強制險收據 (8)發票(受買人/金額)、(9)出廠證明正本 (10",
      "position": ""
    },
    {
      "cid": 17,
      "priority": 10,
      "type": "方向盤",
      "detail": "輪胎轉正看方向盤是否也轉正",
      "position": "正駕"
    },
    {
      "cid": 18,
      "priority": 10,
      "type": "方向盤",
      "detail": "右滾輪(按一下: 語音)",
      "position": "正駕"
    },
    {
      "cid": 19,
      "priority": 10,
      "type": "方向盤",
      "detail": "右滾輪(左右滾動: 車距調整)",
      "position": "正駕"
    },
    {
      "cid": 20,
      "priority": 10,
      "type": "方向盤",
      "detail": "右滾輪(上下滾動: 車速調整)",
      "position": "正駕"
    },
    {
      "cid": 21,
      "priority": 10,
      "type": "方向盤",
      "detail": "左滾輪(按一下: 靜音)",
      "position": "正駕"
    },
    {
      "cid": 22,
      "priority": 10,
      "type": "方向盤",
      "detail": "左滾輪(左右滾動: 上下首歌曲)",
      "position": "正駕"
    },
    {
      "cid": 23,
      "priority": 10,
      "type": "方向盤",
      "detail": "左滾輪(上下滾動: 音量調整)",
      "position": "正駕"
    },
    {
      "cid": 24,
      "priority": 10,
      "type": "方向盤",
      "detail": "喇叭左右側輕壓是否正常",
      "position": "正駕"
    },
    {
      "cid": 25,
      "priority": 10,
      "type": "方向盤",
      "detail": "左右大幅度轉動有無異音",
      "position": "正駕"
    },
    {
      "cid": 26,
      "priority": 10,
      "type": "充電",
      "detail": "AC/DC充電都正常",
      "position": ""
    },
    {
      "cid": 27,
      "priority": 10,
      "type": "外觀",
      "detail": "全車鈑金縫隙、高低差、注意邊角",
      "position": "全車、前後行李箱、四門內側"
    },
    {
      "cid": 28,
      "priority": 10,
      "type": "外觀",
      "detail": "車門/前後箱板金交接處是否烤漆完整",
      "position": ""
    },
    {
      "cid": 29,
      "priority": 10,
      "type": "外觀",
      "detail": "充電孔是否正常(絕緣體是否有脫落現象)",
      "position": ""
    },
    {
      "cid": 30,
      "priority": 10,
      "type": "外觀",
      "detail": "前後擋玻璃、側門玻璃、三角窗玻璃、車頂玻璃檢查破裂與水波痕",
      "position": ""
    },
    {
      "cid": 31,
      "priority": 10,
      "type": "外觀",
      "detail": "前後車燈有無刮傷、缺角、異物在燈罩內",
      "position": ""
    },
    {
      "cid": 32,
      "priority": 10,
      "type": "外觀",
      "detail": "大燈、遠燈、左右方燈、霧燈、日行燈、倒車燈、第三警示燈、煞車燈",
      "position": ""
    },
    {
      "cid": 33,
      "priority": 9,
      "type": "外觀",
      "detail": "車門/前後行李箱防水膠條是否有牢固",
      "position": ""
    },
    {
      "cid": 34,
      "priority": 9,
      "type": "外觀",
      "detail": "板件接縫處公差是否合理(手指頭量測)",
      "position": ""
    },
    {
      "cid": 35,
      "priority": 9,
      "type": "外觀",
      "detail": "ABC柱接合處烤漆是否完整",
      "position": ""
    },
    {
      "cid": 36,
      "priority": 10,
      "type": "外觀",
      "detail": "Keycard B柱感應是否正常",
      "position": "正駕"
    },
    {
      "cid": 37,
      "priority": 10,
      "type": "外觀",
      "detail": "前、後車箱是否開關平順",
      "position": ""
    },
    {
      "cid": 38,
      "priority": 10,
      "type": "車門",
      "detail": "車門開啟是否正常(玻璃是否自動上下歸位)",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 39,
      "priority": 10,
      "type": "底盤",
      "detail": "底盤有無刮痕或少螺絲",
      "position": ""
    },
    {
      "cid": 40,
      "priority": 10,
      "type": "空調",
      "detail": "出風口左右上下調整/風輛大小/溫度高低",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 41,
      "priority": 10,
      "type": "空調",
      "detail": "車內循環/前後除霧按鈕",
      "position": "正駕"
    },
    {
      "cid": 42,
      "priority": 10,
      "type": "後照鏡",
      "detail": "R檔後視鏡是否可往下照",
      "position": "正駕"
    },
    {
      "cid": 43,
      "priority": 9,
      "type": "後照鏡",
      "detail": "後視鏡收合是否正常且左右同步",
      "position": "正駕"
    },
    {
      "cid": 44,
      "priority": 10,
      "type": "玻璃",
      "detail": "車頂玻璃是否無裂痕、水波紋",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 45,
      "priority": 10,
      "type": "玻璃",
      "detail": "前橫擋玻璃是否無裂痕、水波紋",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 46,
      "priority": 10,
      "type": "玻璃",
      "detail": "車門開關玻璃是否自動上下歸位",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 47,
      "priority": 10,
      "type": "座椅",
      "detail": "是否可電動調整(注意有無異音)",
      "position": "正駕,副駕"
    },
    {
      "cid": 48,
      "priority": 10,
      "type": "座椅",
      "detail": "安全帶扣上是否正常感應且可調整長短",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 49,
      "priority": 10,
      "type": "座椅",
      "detail": "坐墊加熱功能是否正常",
      "position": "正駕,副駕"
    },
    {
      "cid": 50,
      "priority": 9,
      "type": "座椅",
      "detail": "是否無破裂、破洞、車縫線不整齊、角落沒拉好狀況",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 51,
      "priority": 10,
      "type": "座椅",
      "detail": "後座ISOFIX兩組是否正常",
      "position": "乘客左,乘客右"
    },
    {
      "cid": 52,
      "priority": 10,
      "type": "座椅",
      "detail": "椅座向前收合",
      "position": "乘客左,乘客右"
    },
    {
      "cid": 53,
      "priority": 9,
      "type": "座椅",
      "detail": "置杯架是否卡卡",
      "position": "乘客左"
    },
    {
      "cid": 54,
      "priority": 8,
      "type": "座椅",
      "detail": "後座枕頭飾板是否服貼",
      "position": "乘客左,乘客右"
    },
    {
      "cid": 55,
      "priority": 10,
      "type": "窗戶",
      "detail": "車窗是否可昇降",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 56,
      "priority": 9,
      "type": "窗戶",
      "detail": "窗戶鍍鉻飾條是否公差過大或有汙損脫膠",
      "position": "正駕,副駕,乘客左,乘客右"
    },
    {
      "cid": 57,
      "priority": 10,
      "type": "撥桿",
      "detail": "左撥桿: 方向燈/遠光燈/自動模式/雨刷(按一下或長按)",
      "position": "正駕"
    },
    {
      "cid": 58,
      "priority": 10,
      "type": "撥桿",
      "detail": "右撥桿: 測試P/D/N/R是否都可正常在螢幕顯示",
      "position": "正駕"
    },
    {
      "cid": 59,
      "priority": 10,
      "type": "撥桿",
      "detail": "右撥桿: 打R檔看倒車與左右側鏡頭影像",
      "position": "正駕"
    },
    {
      "cid": 60,
      "priority": 10,
      "type": "輪胎",
      "detail": "4輪框是否正常(刮痕、磨損、螺絲一輪五顆)",
      "position": ""
    },
    {
      "cid": 61,
      "priority": 10,
      "type": "輪胎",
      "detail": "卡鉗是否正常無斷裂",
      "position": ""
    },
    {
      "cid": 62,
      "priority": 9,
      "type": "輪胎",
      "detail": "輪胎出廠年份(2年內)",
      "position": ""
    },
    {
      "cid": 63,
      "priority": 9,
      "type": "輪胎",
      "detail": "輪胎是否正常(刮痕、磨損)",
      "position": ""
    },
    {
      "cid": 64,
      "priority": 8,
      "type": "輪胎",
      "detail": "胎壓是否正常",
      "position": "正駕"
    },
    {
      "cid": 65,
      "priority": 10,
      "type": "燈",
      "detail": "前環境燈是否正常",
      "position": "正駕"
    },
    {
      "cid": 66,
      "priority": 10,
      "type": "燈",
      "detail": "後燈座是否正常",
      "position": "乘客左"
    },
    {
      "cid": 67,
      "priority": 10,
      "type": "螢幕",
      "detail": "WIFI分享是否正常",
      "position": "正駕"
    },
    {
      "cid": 68,
      "priority": 10,
      "type": "螢幕",
      "detail": "藍芽連結(撥打電話)",
      "position": "正駕"
    },
    {
      "cid": 69,
      "priority": 10,
      "type": "螢幕",
      "detail": "開啟手套箱",
      "position": "正駕"
    },
    {
      "cid": 70,
      "priority": 10,
      "type": "螢幕",
      "detail": "哨兵模式(畫面是否正常)",
      "position": "正駕"
    },
    {
      "cid": 71,
      "priority": 10,
      "type": "螢幕",
      "detail": "車上觀看哨兵模式",
      "position": "正駕"
    },
    {
      "cid": 72,
      "priority": 9,
      "type": "螢幕",
      "detail": "有無刮痕",
      "position": "正駕"
    },
    {
      "cid": 73,
      "priority": 9,
      "type": "螢幕",
      "detail": "是否滑順不卡頓",
      "position": "正駕"
    },
    {
      "cid": 74,
      "priority": 9,
      "type": "螢幕",
      "detail": "USB撥放音樂",
      "position": "正駕"
    },
    {
      "cid": 75,
      "priority": 9,
      "type": "螢幕",
      "detail": "語音聲控(調低溫度)",
      "position": "正駕"
    },
    {
      "cid": 76,
      "priority": 8,
      "type": "螢幕",
      "detail": "里程數30KM以下",
      "position": "正駕"
    },
    {
      "cid": 77,
      "priority": "",
      "type": "座椅",
      "detail": "白沙發 要把安全帶拿起來看 看有沒有壓痕 破損",
      "position": ""
    },
    {
        "cid": 80,
        "priority": "",
        "type": "飾版與縫線",
        "detail": "確認無破損",
        "position": "前方飾版,內門(把手),車頂"
    },
    {
      "cid": 90,
      "priority": "",
      "type": "油壓頂桿",
      "detail": "確認內部頂桿無受傷、活動順暢",
      "position": "前後行李箱"
  }
  ]