import { useState, useEffect } from 'react';
import { isMixedTypeAnnotation } from '@babel/types';
import CheckInfoCard from './card';
import { data } from './data';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
import ResultTable from './result';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';


interface CheckItem {

    cid: number
    priority: number | string
    type: string
    detail: string
    position: string

    recheck: boolean

}

export default function CardList() {

    const [currentCard, setCurrentCard] = useState(0);
    const [progress, setProgress] = useState(0);
    const [finished, setFinished] = useState(false);

    const [cards, setCards] = useState(() => {
        return data.map((item: any) => {
            return {
                cid: item.cid,
                priority: item.priority,
                type: item.type,
                detail: item.detail,
                position: item.position,
                recheck: item.recheck
            }
        })
    });

    const dd = cards[currentCard]

    const markAsRecheck = () => {
        const newCards = [...cards];
        newCards[currentCard].recheck = !newCards[currentCard].recheck;
        setCards(newCards);
    }

    const updateProgress = () => {
        setProgress(Math.round((currentCard + 1) / data.length * 100));

        // keep records to localstorage
        localStorage.setItem("cards", JSON.stringify(cards))
        localStorage.setItem("last", currentCard.toString(10))
    }

    const nextCard = () => {

        if (currentCard <= data.length - 1) {
            if (currentCard + 1 == cards.length) {
                setFinished(true);
            } else {
                setCurrentCard(currentCard + 1);
            }
            updateProgress();
        }

    }

    const priviousCard = () => {
        if (currentCard > 0) {
            setCurrentCard(currentCard - 1)
            updateProgress();
        }
    }

    const resetResult = () => {
        localStorage.removeItem("cards");
        localStorage.removeItem("last");
        
        setCurrentCard(0)
        setCards(data.map((item: any) => {
            return {
                cid: item.cid,
                priority: item.priority,
                type: item.type,
                detail: item.detail,
                position: item.position,
                recheck: item.recheck
            }
        }))
        updateProgress();
        setFinished(false);
    }

    useEffect(() => {
        // saved cards
        const saved = localStorage.getItem("cards")
        if (saved) {
            const initialValue = JSON.parse(saved);
            console.log("[RestoreFromSaved] cards:", initialValue);
            setCards(initialValue);
        }

        // last progress
        const savedProgress = localStorage.getItem("last")
        if (savedProgress) {
            const lprog = parseInt(savedProgress)
            console.log("[RestoreFromSaved] last progress:", lprog);
            setCurrentCard(lprog + 1 >= cards.length ? cards.length - 1 : lprog + 1)
            setProgress(lprog);
        }

    }, []);

    return (

        <Box>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        交車神器
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
            </AppBar>
            <Box sx={{ width: '100%' }}>
                <LinearProgressWithLabel value={progress} />
            </Box>
            {!finished &&
            <Box style={{ position: "absolute", left: 5, right: 5 }}>
                <CheckInfoCard
                    cid={dd.cid}
                    priority={dd.priority}
                    type={dd.type}
                    detail={dd.detail}
                    positions={dd.position.split(",")}
                    markedAsRechecked={dd.recheck}

                    handleNext={nextCard}
                    handlePrivious={priviousCard}
                    handleMarkAsRecheck={markAsRecheck}
                ></CheckInfoCard>
                </Box>
            }
            {finished &&
                <>
                    <Typography color="text.primary" variant="h3">
                        <Box sx={{ fontSize: 32 }}>恭喜完成驗收！</Box>
                        <Button onClick={resetResult}>清除結果並重新開始</Button>
                    </Typography>

                    <Box>
                        <ResultTable data={cards}></ResultTable>

                    </Box>
                </>
            }

            <Box style={{ position: "absolute", bottom: 0, color: 'gray', fontSize: 10, justifyContent:"end" }}>
                <span>© 2021, by zzchen All rights reserved. </span><br/>
                <span>本站內容搜集自車友、論壇與群組流傳，若有侵權，請聯絡管理員。</span>
            </Box>
        </Box>

    )

}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}